import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Col, Space } from 'antd';
import { navigate } from 'gatsby';
const { Text } = Typography;

const CongloButton = ({ icon, title, goTo }) => {
  return (
    <div
      onClick={() => navigate(goTo)}
      align="middle"
      justify="center"
      className="container-button-service"
    >
      <Col span={24}>
        <Space direction="horizontal" size="small">
          <img
            alt="tavel.svg"
            src={icon}
            style={{ width: 50, marginTop: 10 }}
          />
          <Text>{title}</Text>
        </Space>
      </Col>
    </div>
  );
};

CongloButton.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  goTo: PropTypes.string,
};

export default CongloButton;
