import React, { useState } from 'react';
import '../styles/method-payment.scss';
import { Layout, Typography, Button, Row, Col, Form, Input, Radio } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import CongloButton from '../components/conglo-button/conglo-button';
const { Header, Content } = Layout;
const { Title, Text } = Typography;
// import CreditCard from '../components/credit-card/credit-card';
import { useSelector, useDispatch } from 'react-redux';

import { navigate } from 'gatsby';

const Payment = () => {
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();

  const { plan } = useSelector(state => state);

  const payments = [
    {
      id: '1',
      type: 'saldo',
      value: 10000,
    },
  ];

  const savePayment = payment => {
    dispatch({
      type: 'SET_PAYMENT',
      data: payment,
    });
    navigate(`/summary/${plan}`);
  };

  return (
    <Layout>
      <Header className="site-layout-background header" style={{ padding: 0 }}>
        <Button
          style={{ textAlign: 'left' }}
          className="icon-color-primary"
          type="text"
          size="large"
          href={`/summary/${plan}`}
          icon={<ArrowLeftOutlined />}
        />
        <Text className="title-step" strong>
          Seleccione el Medio de Pago
        </Text>
      </Header>
      <Content className="site-layout-background">
        <div className="content-payment">
          <Row>
            <Title className="subtitle-step" level={5}>
              Medios de pago disponibles, puedes registrar más con el botón +, o
              seleccionar uno para realizar el pago.
            </Title>
          </Row>
          <br />
          {payments &&
            payments.map(payment => {
              return (
                <Row key={payment.id}>
                  <Col span={4}>
                    <Radio onClick={() => savePayment(payment)}></Radio>
                  </Col>
                  <Col span={6}>{payment.type}</Col>
                  <Col span={6}>{payment.value}</Col>
                </Row>
              );
            })}
          <br />
          <Row gutter={[0, 24]}>
            <Text strong>
              Opciones disponibles para arreglar balances o saldo
            </Text>
          </Row>
          <Row justify="center" gutter={[16, 24]}>
            <Col span={24}>
              <CongloButton
                title=" Tarjeta Débito / Crédito"
                className="btn-payment"
                type="primary"
                onClick={() => {
                  setShowForm(!showForm);
                }}
              ></CongloButton>
            </Col>
          </Row>
          {showForm && (
            <Row>
              <Form name="add_payment" onFinish={savePayment}>
                <Form.Item>
                  <Col span={24}>
                    <Text className="subtitle-step" level={5}>
                      Número de tarjeta
                    </Text>
                  </Col>
                </Form.Item>
                <Form.Item name="number">
                  <Input size="large" />
                </Form.Item>
                <Row justify="start">
                  <Col span={13}>
                    <Text className="subtitle-step" level={5}>
                      Fecha expiración
                    </Text>
                  </Col>
                  <Col span={8}>
                    <Text className="subtitle-step" level={5}>
                      CVV
                    </Text>
                  </Col>
                </Row>
                <Row justify="space-around">
                  <Col span={12}>
                    <Form.Item name="expDate">
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item name="cvv">
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                </Row>
                <Col span={24}>
                  <Text className="subtitle-step" level={5}>
                    Nombre tarjeta
                  </Text>
                </Col>
                <Form.Item name="name">
                  <Input size="large" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Guardar
                  </Button>
                </Form.Item>
              </Form>
            </Row>
          )}
          <Row gutter={[16, 24]}>
            <Col span={24}>
              <CongloButton title="Paypal" />
            </Col>
            <Col span={24}>
              <CongloButton title="Saldo teléfono" />
            </Col>
            <Col span={24}>
              <CongloButton title="Depósito bancario" />
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};
export default Payment;
